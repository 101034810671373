import {
  ACCOUNT_SETUP_COMPLETION_KEY,
  ANNOUNCEMENTS_KEY,
  APP_THEME_STORAGE_KEY,
  AUTH_APP_URL,
  LOGIN_PATH,
  SIGN_OUT_PATH,
  SIGN_UP_PATH,
  USER_BALANCE_STATE_KEY,
  USER_EMPTY_WALLETS_STATE_KEY,
} from "app/constants/variables";
import history from "./history";
import storage from "./storage";

export interface LocationState {
  from?: string;
  logout?: boolean;
}

export default function logout(from = "/") {
  const appTheme = localStorage.getItem(APP_THEME_STORAGE_KEY);

  storage.clear();

  if (appTheme) {
    localStorage.setItem(APP_THEME_STORAGE_KEY, appTheme);
  }

  history.replace(`/${LOGIN_PATH}`, { from });
}

export function cleanupStorage() {
  // We want to persist these items across many sessions, hence we cache them before clearing the whole storage
  const cache = [
    APP_THEME_STORAGE_KEY,
    ANNOUNCEMENTS_KEY,
    ACCOUNT_SETUP_COMPLETION_KEY,
    USER_BALANCE_STATE_KEY,
    USER_EMPTY_WALLETS_STATE_KEY,
  ].reduce((acc, key) => {
    const val = localStorage.getItem(key);
    if (val) {
      return [...acc, { key, value: val }];
    }
    return acc;
  }, [] as Array<{ key: string; value: string }>);

  storage.clear();

  // set cached items back to local storage
  cache.forEach(({ key, value }) => {
    localStorage.setItem(key, value);
  });
}

function redirectToAuthURL(path: string) {
  const authURL = new URL(`/${path}`, AUTH_APP_URL);

  const redirectURL = new URL(window.location.origin);
  redirectURL.searchParams.set("from", window.location.pathname);

  authURL.searchParams.set("redirect", redirectURL.href);

  window.location.replace(authURL);
}

export function gotoLogin() {
  cleanupStorage();

  // history.replace(`/${LOGIN_PATH}`, { from });
  redirectToAuthURL(LOGIN_PATH);
}

export function gotoSignup() {
  cleanupStorage();
  redirectToAuthURL(SIGN_UP_PATH);
}

export const gotoSignout = () => {
  // history.push(`/${SIGN_OUT_PATH}`, { from: window.location.pathname });

  cleanupStorage();

  redirectToAuthURL(SIGN_OUT_PATH);
};
