import { useState } from "react";
import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import TextAreaField from "app/components/ui/TextAreaField";
import CheckBox from "app/components/ui/CheckBox";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import usePaymentAccount from "app/hooks/usePaymentAccount";

import { getSchema } from "../utils";

interface Props {
  accountType: ConnectPaymentMethod;
}

export default function AddUSDAccount(props: Props) {
  const { accountType } = props;

  const [intermediary, setIntermediary] = useState(
    accountType.country === "NG"
  );

  const navigate = useNavigate();
  const { addAccount, isAddingPaymentAccount } = usePaymentAccount();

  const schema = getSchema({
    areIntermediaryFieldsRequired: accountType.country === "NG",
    isBusinessAccount: accountType.type === "business",
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isPersonalAccount = accountType.type === "personal";

  const onSubmit = handleSubmit((data) => {
    const fields = Object.entries(data)
      .filter(([_, value]) => value !== "")
      .map(([key, value]) => ({ name: key, value }));

    addAccount(
      {
        payment_method: accountType.id,
        fields,
      },
      {
        onSuccess: () => navigate("/profile/payment-methods/bank-accounts"),
      }
    );
  });

  return (
    <form onSubmit={onSubmit} tw="mt-[24px]">
      <Card tw="mt-[10px] p-[20px]">
        <Text medium semiBold>
          Account details
        </Text>

        <AccountDetails>
          <TextField
            label="Account number or IBAN"
            placeholder="Enter your account number or IBAN"
            type="text"
            {...register("account_number")}
            error={!!errors.account_number?.message}
            errorMessage={errors.account_number?.message as string}
          />

          <TextField
            label="Swift code or Routing number"
            placeholder="Enter Swift code or Routing number"
            type="text"
            {...register("swift_code")}
            error={!!errors.swift_code?.message}
            errorMessage={errors.swift_code?.message as string}
          />

          <TextField
            label="Bank name"
            placeholder="Enter bank name"
            type="text"
            {...register("bank_name")}
            error={!!errors.rootbank_name?.message}
            errorMessage={errors.bank_name?.message as string}
          />

          <TextAreaField
            label="Bank address"
            placeholder="Enter bank address"
            {...register("bank_address")}
            error={!!errors.bank_address?.message}
            errorMessage={errors.bank_address?.message as string}
          />
        </AccountDetails>

        <BeneficiaryDetails>
          <Text medium semiBold>
            Beneficiary Details
          </Text>

          <TextField
            label={`${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            type="text"
            {...register(
              isPersonalAccount
                ? "recipient_account_name"
                : "company_account_name"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_account_name?.message
                : !!errors.company_account_name?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_account_name?.message
                : errors.company_account_name?.message) as string
            }
          />

          <TextAreaField
            label={`${isPersonalAccount ? "Recipient" : "Company"} address`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } address`}
            {...register(
              isPersonalAccount ? "recipient_address" : "company_address"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_address?.message
                : !!errors.company_address?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_address?.message
                : errors.company_address?.message) as string
            }
          />

          {accountType.country !== "NG" && (
            <div tw="flex gap-[8px] mt-[8px]">
              <CheckBox
                checked={intermediary}
                onChange={(e) => setIntermediary(e.target.checked)}
                width={16}
              />
              <Text>Intermediary Bank details</Text>
            </div>
          )}

          {intermediary && (
            <>
              <TextField
                label="Intermediary bank name"
                placeholder="Enter intermediary bank name"
                type="text"
                {...register("intermediary_bank_name")}
                error={!!errors.intermediary_bank_name?.message}
                errorMessage={errors.intermediary_bank_name?.message as string}
              />

              <TextAreaField
                label="Intermediary bank address"
                placeholder="Enter intermediary bank address"
                {...register("intermediary_bank_address")}
                error={!!errors.intermediary_bank_address?.message}
                errorMessage={
                  errors.intermediary_bank_address?.message as string
                }
              />

              <TextField
                label="Intermediary Swift code or Routing number"
                placeholder="Enter intermediary Swift code or Routing number"
                type="text"
                {...register("intermediary_swift_code")}
                error={!!errors.intermediary_swift_code?.message}
                errorMessage={errors.intermediary_swift_code?.message as string}
              />
            </>
          )}
        </BeneficiaryDetails>

        <Button
          primary
          fullWidth
          type="submit"
          loading={isAddingPaymentAccount}
        >
          Save
        </Button>
      </Card>
    </form>
  );
}

const AccountDetails = styled.div`
  ${tw`flex flex-col gap-[16px] mt-[16px] mb-[24px] `}
`;

const BeneficiaryDetails = styled.div`
  ${tw`flex flex-col gap-[16px] pb-[24px] mb-[16px] border-b border-[#00000014] dark:border-[#FFFFFF14]`}
`;
