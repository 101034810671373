import styled from "@emotion/styled";
import tw from "twin.macro";
import { Link } from "react-router-dom";

import useGetNotificationsCount from "app/hooks/useGetNotificationsCount";
import { ReactComponent as NotificationIcon } from "app/assets/icons/bell-outlined.svg";
import { ReactComponent as NewNotificationsIcon } from "app/assets/icons/bell-eclipse.svg";

const DashboardHeaderNotification = () => {
  const { counts } = useGetNotificationsCount();
  return (
    <NotificationButton to="/notifications">
      {(Number(counts) || 0) > 0 ? (
        <NewNotificationsIcon />
      ) : (
        <NotificationIcon />
      )}
    </NotificationButton>
  );
};

const NotificationButton = styled(Link)`
  ${tw`w-[36px] h-[36px] flex items-center justify-center`};

  svg {
    ${tw`w-[24px] h-[24px]`};

    path {
      ${tw`dark:fill-current`}
    }
  }
`;

export default DashboardHeaderNotification;
