import axios from "axios";

import {
  AIRTABLE_BASE_ID,
  AIRTABLE_KEY,
  BUSHA_ENV,
} from "app/constants/variables";
import Announcement from "app/models/announcement";
import { GenericAirtableListResponse } from "../types";

export const AIRTABLE_PRODUCT = "alice";

const requestUrl = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/announcements?filterByFormula=AND(SEARCH(%22${AIRTABLE_PRODUCT}%22%2CPRODUCT)%2C+DATETIME_DIFF(expiry%2C+NOW()%2C++'days')+%3E+-1%2C+%7BENVIRONMENT%7D+%3D+%22${BUSHA_ENV}%22)`;

export const listAnouncements = () => {
  return axios.get<GenericAirtableListResponse<Announcement>>(requestUrl, {
    headers: {
      Authorization: `Bearer ${AIRTABLE_KEY}`,
    },
  });
};

const AnncounementService = {
  listAnouncements,
};

export default AnncounementService;
