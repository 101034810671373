import * as React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";

import Modal from "app/components/ui/Modal";
import Announcement from "app/models/announcement";
import { useAppTheme } from "app/providers/AppThemeProvider";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import media from "app/styles/media";
import SubHeader from "app/styles/SubHeader";
import StepIndicator from "./StepIndicator";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";

interface Props {
  open: boolean;
  onClose: () => void;
  setIsOpen: (b: boolean) => void;
  config: Announcement[];
  isDismissed: boolean;
}

export default function AnnouncementModal(
  props: React.PropsWithChildren<Props>
) {
  const { open, onClose, isDismissed, setIsOpen, config } = props;
  const navigate = useNavigate();
  const [activeFrame, setActiveFrame] = React.useState(0);

  const theme = useAppTheme();

  const onAction = (a: Announcement) => {
    onClose();
    if (a.fields.action_link.startsWith("http")) {
      window.open(a.fields.action_link, "_blank");
    } else {
      navigate(a.fields.action_link);
    }
  };

  const contentRef = React.useRef(null);

  const onPrev = () => {
    setActiveFrame((s) => s - 1);
  };

  const onNext = () => {
    setActiveFrame((s) => s + 1);
  };

  React.useEffect(() => {
    const targetElement = contentRef.current;

    if (isDismissed) return;

    const openDelayedModal = async () => {
      await Promise.resolve();
      setIsOpen(true);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            openDelayedModal();
          }
        });
      },
      {
        root: document.querySelector("main.content"),
        threshold: 0,
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [isDismissed, setIsOpen]);

  return (
    <>
      <div ref={contentRef}></div>

      <FeatureDialog
        tw="max-h-full"
        open={open}
        onClose={onClose}
        className={config.length > 1 ? "multiple" : "single"}
        title="✨   New features for you "
      >
        {/* Single announcement  */}

        {config.length === 1 &&
          config.map((c) => {
            return (
              <Container>
                <div className="main">
                  <GIFBox>
                    <img
                      src={
                        theme === "light"
                          ? c.fields.image[0].url
                          : c.fields.dark_image[0].url
                      }
                      alt={c.fields.title}
                      loading="eager"
                      tw="mobile:hidden w-full h-[320px] object-center object-cover"
                    />

                    <img
                      src={
                        theme === "light"
                          ? c.fields.image[0].url
                          : c.fields.dark_image[0].url
                      }
                      loading="eager"
                      alt={c.fields.title}
                      tw="hidden mobile:block"
                    />
                  </GIFBox>

                  <Actions>
                    <Header semiBold>{c.fields.title}</Header>

                    {typeof c.fields.description === "string" ? (
                      <Text subdued>
                        {c.fields.description.replace(/<[^>]*>/g, "")}
                      </Text>
                    ) : (
                      c.fields.description
                    )}

                    <div tw="flex w-full mt-[24px] gap-[16px] mobile:flex-col-reverse">
                      <Button tw="flex-1" tertiary onClick={onClose}>
                        {c.fields.cancel_text || "Dismiss"}
                      </Button>

                      <Button tw="flex-1" primary onClick={() => onAction(c)}>
                        {c.fields.action_text}
                      </Button>
                    </div>
                  </Actions>
                </div>
              </Container>
            );
          })}

        {/* Multiple announcements */}

        {config.length > 1 && (
          <div tw=" pt-[16px] mobile:pt-0 pb-[24px]">
            <div tw="flex justify-between items-center gap-[24px] mobile:flex-col border-b border-[#E5E5E5] dark:border-[#1A1A1A] pb-[16px]">
              <div tw="pl-[24px] mobile:px-[16px] mobile:order-last">
                <SubHeader tw="text-[2.4rem] mb-[8px]">
                  {config[activeFrame].fields.title}
                </SubHeader>

                {typeof config[activeFrame].fields.description === "string" ? (
                  <Text subdued medium>
                    {config[activeFrame].fields.description.replace(
                      /<[^>]*>/g,
                      ""
                    )}
                  </Text>
                ) : (
                  config[activeFrame].fields.description
                )}
              </div>
              <div tw="mobile:bg-[#e6f2fd] mobile:dark:bg-[#294960] pr-[24px] mobile:pr-0">
                <img
                  src={
                    theme === "light"
                      ? config[activeFrame].fields.image[0].url
                      : config[activeFrame].fields.dark_image[0].url
                  }
                  alt={config[activeFrame].fields.title}
                  loading="eager"
                  tw="max-w-[260px] mobile:max-w-full"
                />
              </div>
            </div>

            <div tw="px-[24px] mobile:px-[16px] flex justify-between pt-[16px] items-center">
              <button
                onClick={onPrev}
                disabled={activeFrame === 0}
                tw="disabled:opacity-[0.4] flex gap-[4px] rounded-[4px] items-center text-[14px] font-semibold px-[12px] py-[6px] border border-[#E5E5E5] bg-[#F9F9F9] dark:border-[#1A1A1A] dark:bg-[#0D0D0D]"
              >
                {" "}
                <CaretRight tw="rotate-180 fill-current" /> Previous
              </button>

              <StepIndicator
                frames={config.length}
                activeFrame={activeFrame + 1}
              />

              <button
                onClick={activeFrame + 1 === config.length ? onClose : onNext}
                tw="disabled:opacity-[0.4] flex gap-[4px] rounded-[4px] items-center text-[14px] font-semibold px-[12px] py-[6px] border border-[#E5E5E5] bg-[#F9F9F9] dark:border-[#1A1A1A] dark:bg-[#0D0D0D]"
              >
                {activeFrame + 1 === config.length ? (
                  "Done"
                ) : (
                  <>
                    Next
                    <CaretRight tw="fill-current" />
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </FeatureDialog>
    </>
  );
}

const FeatureDialog = styled(Modal)`
  .single {
    ${tw`relative w-[544px] bg-[#e6f2fd] dark:bg-[#5A5C5E]`}
  }

  .multiple {
    ${tw`relative w-[720px]`}
  }

  .single {
    .header {
      padding: 16px 30px;
    }
  }

  button[aria-label="Close"] {
    svg {
      path {
        ${tw`dark:fill-[#fff]`};
      }
    }
  }
`;

const Container = styled.div`
  ${tw`relative`}

  > .main {
    ${media.mobile} {
      width: 100%;
    }
  }
`;

const GIFBox = styled.div`
  ${tw`w-full flex justify-center items-center`};
`;

const Actions = styled.div`
  ${tw`w-full bg-white flex flex-col p-[30px] dark:bg-black`};
`;
