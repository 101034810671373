const Paths = {
  recommendation: (name: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/recommendations%2F${name.toLowerCase()}.png?alt=media`,

  coinSVG: (symbol: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/assets%2F${symbol.toUpperCase()}.svg?alt=media`,

  coinPNG: (symbol: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/assets_png%2F${symbol.toUpperCase()}.png?alt=media`,

  depositProviders: (provider: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/deposit_providers%2F${provider.toLowerCase()}.png?alt=media`,

  payoutProviders: (provider: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/payout_providers%2F${provider.toLowerCase()}.png?alt=media`,

  networkProvidersPNG: (provider: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/network_providers%2F${provider.toLowerCase()}.png?alt=media`,
  networkProvidersSVG: (provider: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/network_providers%2F${provider.toLowerCase()}.svg?alt=media`,
};

export default Paths;
