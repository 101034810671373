import * as React from "react";

import useModalState from "app/hooks/useModalState";
import storage from "app/lib/storage";
import { ANNOUNCEMENTS_KEY } from "app/constants/variables";

import AnnouncementModal from "./AnnouncementModal";
import useListAnnouncements from "../hooks/useListAnnouncements";

export default function Announcement() {
  const { isOpen, setIsOpen, closeModal } = useModalState();

  const { data } = useListAnnouncements();

  const announcements = React.useMemo(
    () => data?.data.records ?? [],
    [data?.data.records]
  );

  const viewedAnnouncements = React.useMemo(
    () => storage.get(ANNOUNCEMENTS_KEY) ?? {},
    []
  );

  const unSeenAnnouncement = React.useMemo(
    () =>
      announcements.filter(
        (item) => !Object.keys(viewedAnnouncements).includes(item.id)
      ),
    [viewedAnnouncements, announcements]
  );

  const announcement = unSeenAnnouncement;

  const announcementStore = storage.get(ANNOUNCEMENTS_KEY) as Record<
    string,
    string
  >;

  const dismissedAnnoucements = {
    ...announcementStore,
  };

  const lastAnnouncement = announcement.find(
    (a) => a.fields.order === announcement.length
  );

  const isDismissed = Object.keys(dismissedAnnoucements).includes(
    lastAnnouncement?.id ?? ""
  );

  const onClose = () => {
    closeModal();
    setTimeout(
      () =>
        storage.set(ANNOUNCEMENTS_KEY, {
          ...dismissedAnnoucements,
          [lastAnnouncement?.id as string]: lastAnnouncement?.id,
        }),
      1000
    );
  };

  return (
    <>
      {announcement.length > 0 && (
        <AnnouncementModal
          isDismissed={isDismissed}
          open={isOpen}
          setIsOpen={setIsOpen}
          onClose={onClose}
          config={announcement}
        />
      )}
    </>
  );
}
