import * as React from "react";
import { useNavigate } from "react-router-dom";

import usePaymentAccount from "app/hooks/usePaymentAccount";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import Button from "app/components/ui/Button";
import TextField from "app/components/ui/TextField";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import Card from "app/components/ui/Card";

interface Props {
  method: ConnectPaymentMethod;
}
const AddAccountForm = (props: Props) => {
  const { method } = props;

  const usernameField = method.fields.find((f) => f.name === "username");

  const navigate = useNavigate();

  const [fieldValues, setFieldValues] =
    React.useState<Record<string, string>>();

  React.useEffect(() => {
    const initialValues = method.fields.reduce((acc, current) => {
      acc[current.name] = (usernameField?.validator ?? "").includes("@")
        ? "@"
        : "";
      return acc;
    }, {} as any);
    setFieldValues(initialValues);
  }, [method.fields, usernameField?.validator]);

  const updateFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const fieldName = e.target.name;

    const field = method.fields.find((f) => f.name === fieldName);
    const showPrefix = (field?.validator ?? "").includes("@");

    setFieldValues((f) => ({
      ...f,
      [e.target.name]: showPrefix ? `@${value.replaceAll("@", "")}` : value,
    }));
  };

  const { isAddingPaymentAccount: isLoading, addAccount } = usePaymentAccount();

  const submitAccountData = () => {
    const fields = Object.entries(fieldValues ?? {}).map(([key, value]) => ({
      name: key,
      value: value,
    }));

    addAccount(
      {
        payment_method: method.id,
        fields: fields,
      },
      { onSuccess: () => navigate("/profile/payment-methods/other-accounts") }
    );
  };

  const disableBtn = Object.values(fieldValues ?? {}).every(
    (value) => value !== ""
  );

  return (
    <>
      <Header semiBold tw="text-[28px]">
        {method.name}
      </Header>

      <Text subdued medium>
        {method.fields[0].message}
      </Text>

      <Card tw="mt-[24px]">
        <div tw="p-[20px] flex flex-col gap-[10px]">
          <div tw="border-b border-[rgba(0, 0, 0, 0.12)] dark:border-gray-800 pb-[16px] grid gap-y-[16px]">
            {fieldValues &&
              method.fields.map((field, index) => (
                <TextField
                  key={index}
                  label={field.display_name}
                  placeholder={field.hint_word}
                  value={fieldValues[field.name]}
                  name={field.name}
                  onChange={updateFieldValue}
                />
              ))}
          </div>

          <Button
            primary
            fullWidth
            onClick={submitAccountData}
            disabled={!disableBtn}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </Card>
    </>
  );
};

export default AddAccountForm;
