import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import parseError from "app/lib/parseError";
import { createUserPaymentAccount } from "app/api/payment_accounts";
import { CreateUserPaymentAccountPayload } from "app/api/payment_accounts/types";
import { useToast } from "app/providers/ToastProvider";
import { BaseResponse } from "app/api/types";

const usePaymentAccount = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleError = (error: AxiosError<any>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    showToast("", res.data.message || "", "success");
    queryClient.invalidateQueries(["payment_accounts"]);
  };

  const { isLoading: isAddingPaymentAccount, mutate: addAccount } = useMutation(
    (d: CreateUserPaymentAccountPayload) => createUserPaymentAccount(d),
    { onError: handleError, onSuccess: handleSuccess }
  );

  return {
    isAddingPaymentAccount,
    addAccount,
  };
};

export default usePaymentAccount;
