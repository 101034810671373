import crypto from "crypto";
import storage from "./storage";
import { TOKEN_STORAGE_KEY } from "app/constants/variables";

export const generateHMAC = (userID: string, secretKey: string) => {
  const hash = crypto
    .createHmac("sha256", secretKey)
    .update(userID)
    .digest("hex");
  return hash;
};

type ParsedToken = {
  exp: string;
  iat: string;
  ipaddrs: number;
};

export const parseToken = () => {
  const token: { access_token: string } = storage.get(TOKEN_STORAGE_KEY);

  var base64Url = token.access_token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload) as ParsedToken;
};
