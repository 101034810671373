import { formatMoneyNoCurrency } from "app/lib/money";

export function addCommaToDigits(digit: string) {
  if (Number(digit) === 0) {
    return digit;
  }

  const _d = digit.split(".");

  if (_d.length === 2) {
    if (
      !isNaN(Number(_d[0])) &&
      (_d[1].trim() === "" || !isNaN(Number(_d[1])))
    ) {
      return formatMoneyNoCurrency(Number.parseFloat(digit), "crypto");
    }
  }

  if (!digit || isNaN(Number(digit)) || digit === "0" || Number(digit) === 0) {
    return digit;
  }

  return formatMoneyNoCurrency(Number.parseFloat(digit), "crypto");
}

export function removeCommaFromDigits(digit: string) {
  const value = digit.replaceAll(",", "");

  return value;
}
