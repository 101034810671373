import { AUTH_URL } from "app/constants/variables";
import request from "../request";
import { BaseResponse } from "../types";
import {
  GetNotificationsCountResponse,
  GetNotificationCategoriesResponse,
  GetNotificationsParams,
  GetNotificationsResponse,
} from "./types";

const defaultOption = {
  baseURL: AUTH_URL,
};

export const getNotifications = (params: GetNotificationsParams) => {
  return request.get<GetNotificationsResponse>(`/notification`, {
    params,
    ...defaultOption,
  });
};

export const getNotificationsCount = () => {
  return request.get<GetNotificationsCountResponse>(
    `/notification/new`,
    defaultOption
  );
};

export const updateNotificationLastViewTime = () => {
  return request.put<BaseResponse>(
    `/notification/last-view`,
    null,
    defaultOption
  );
};

export const getNotificationCategories = () => {
  return request.get<GetNotificationCategoriesResponse>(
    `/notification/categories`,
    defaultOption
  );
};
