import { AUTH_URL } from "app/constants/variables";

import {
  CreateNextOfKinPayload,
  FinalizeBvnChangePayload,
  FinalizePhoneChangePayload,
  GetNextOfKinResponse,
  GetProfileResponse,
  GetRequiredDocumentsResponse,
  GetSecurityResponse,
  GetUpgradeMethodResponse,
  GetUserLimitsResponse,
  InitializeBvnChangePayload,
  InitializeBvnChangeResponse,
  InitializePhoneChangePayload,
  UpdateAddressPayload,
  UpdateDisplayCurrencyPayload,
  UpdateDOBPayload,
  UpdateNextOfKinPayload,
  UpdateUsernamePayload,
} from "./types";

import request from "../request";
import { BaseResponse, GenericResponse } from "../types";
import { DisplayCurrency } from "app/models/displayCurrencies";
import { NameChangeRequirement } from "app/models/nameChangeRequirement";

const defaultOption = { baseURL: AUTH_URL };

export const getSecurity = () => {
  return request.get<GetSecurityResponse>("/me/security", defaultOption);
};

export const getProfile = () => {
  return request.get<GetProfileResponse>("/me", defaultOption);
};

export const getNextOfKin = () => {
  return request.get<GetNextOfKinResponse>("/me/next_of_kin", defaultOption);
};

export const updateUsername = (payload: UpdateUsernamePayload) => {
  return request.put<BaseResponse>(`/me/username`, payload, defaultOption);
};

export const updateDOB = (payload: UpdateDOBPayload) => {
  return request.put<BaseResponse>(`/me/dob`, payload, defaultOption);
};

export const initializePhoneChange = (
  payload: InitializePhoneChangePayload
) => {
  return request.post<BaseResponse>(
    `/me/security/phone/initialize`,
    payload,
    defaultOption
  );
};

export const finalizePhoneChange = (payload: FinalizePhoneChangePayload) => {
  return request.post<BaseResponse>(
    `/me/security/phone/finalize`,
    payload,
    defaultOption
  );
};

export const getUserLimits = () => {
  return request.get<GetUserLimitsResponse>("/me/limits", defaultOption);
};

export const getVerificationUpgradeMethod = () => {
  return request.get<GenericResponse<GetUpgradeMethodResponse>>(
    "/me/upgrade_methods",
    defaultOption
  );
};

export const initializeBvnChange = (payload: InitializeBvnChangePayload) => {
  return request.post<InitializeBvnChangeResponse>(
    `/me/security/bvn/initialize`,
    payload,
    defaultOption
  );
};

export const finalizeBvnChange = (payload: FinalizeBvnChangePayload) => {
  return request.post<BaseResponse>(
    `/me/security/bvn/finalize`,
    payload,
    defaultOption
  );
};

export const getRequiredDocuments = () => {
  return request.get<GetRequiredDocumentsResponse>(
    "/me/documents/types",
    defaultOption
  );
};

export const createNextofKin = (payload: CreateNextOfKinPayload) => {
  return request.post<BaseResponse>(`/me/next_of_kin`, payload, defaultOption);
};

export const updateNextofKin = (payload: UpdateNextOfKinPayload) => {
  return request.put<BaseResponse>(`/me/next_of_kin`, payload, defaultOption);
};

export const updateAddress = (payload: UpdateAddressPayload) => {
  return request.put<BaseResponse>(`/me/address`, payload, defaultOption);
};

export const getDisplayCurrency = () => {
  return request.get<GenericResponse<DisplayCurrency[]>>(
    "/me/display_currency",
    defaultOption
  );
};

export const getNameChangeRequirements = () => {
  return request.get<GenericResponse<NameChangeRequirement>>(
    "/me/name-change",
    defaultOption
  );
};

export const updateDisplayCurrency = (
  payload: UpdateDisplayCurrencyPayload
) => {
  return request.put<BaseResponse>(
    `/me/display_currency`,
    payload,
    defaultOption
  );
};
