import { useQuery } from "@tanstack/react-query";

import AnncounementService from "app/api/announcements";

export default function useListAnnouncements() {
  const { data, status, error, refetch, isFetching, isLoading, fetchStatus } =
    useQuery(["announcements"], AnncounementService.listAnouncements);

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
    isLoading,
    fetchStatus,
  };
}
