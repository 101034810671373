import { useProfile } from "app/providers/ProfileProvider";
import * as React from "react";
import useGetInfiniteCurrencies from "./useGetInfiniteCurrencies";
import useUpdatedEffect from "./useUpdatedEffect";

const useCurrencies = () => {
  const {
    isFetching,
    isLoading,
    currencies,
    error,
    getCurrencies,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetInfiniteCurrencies({
    params: {
      limit: 20,
    },
  });
  const profile = useProfile();

  useUpdatedEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [currencies, hasNextPage]);

  const memoizedCurrencies = React.useMemo(
    () => currencies,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(currencies)]
  );

  const getCurrency = React.useCallback(
    (code: string) => {
      return (memoizedCurrencies || []).find(
        (currency) => currency.code.toLowerCase() === code.toLowerCase()
      );
    },
    [memoizedCurrencies]
  );

  const isUserCurrencySupported = React.useMemo(() => {
    return (memoizedCurrencies || []).findIndex(
      (currency) =>
        currency.code.toLowerCase() === profile?.currency_code.toLowerCase()
    );
  }, [memoizedCurrencies, profile?.currency_code]);

  return {
    isFetching,
    isLoading,
    currencies,
    error,
    getCurrencies,
    isFetchingNextPage,
    getCurrency,
    isUserCurrencySupported,
  };
};

export default useCurrencies;
