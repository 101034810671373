import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import LoaderContainer from "app/components/ui/LoaderContainer";
import { HOME_PATH } from "app/constants/variables";
import { useSetToken } from "app/providers/TokenProvider";

export default function Auth() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const refresh_token = searchParams.get("refresh_token") ?? undefined;
  const previousPathname = searchParams.get("from");

  const navigate = useNavigate();

  const setAppTokens = useSetToken();

  useEffect(() => {
    const accessToken = params.token;
    let tokens = {
      access_token: accessToken,
      refresh_token,
    };

    setAppTokens(tokens);

    navigate(previousPathname ?? HOME_PATH);
  }, [navigate, params.token, refresh_token, setAppTokens, previousPathname]);

  return <LoaderContainer loading screen />;
}
