import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";

import Account, { FiatAccount } from "app/models/account";
import { formatMoneyNoCurrency, formatToFractionalMoney } from "app/lib/money";
import { ConnectPaymentAccount } from "app/models/paymentAccount";
import { UserBank } from "app/models/userBank";
// import { BadgeProps } from "@shopify/polaris";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);

/**
 * Converts the first letter of the string to uppercase
 */
export const capitalizeStr = (str: string) => {
  if (typeof str !== "string") return "";

  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
};

/**
 * Get file size of a File object in kilobytes
 */
export const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

/**
 * Decode string
 */
export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

/**
 * Get english possessive nouns
 */
export const getPossessiveNoun = (noun: string) => {
  if (noun.endsWith("s")) {
    return `${noun}'`;
  }
  return `${noun}'s`;
};

/**
 * Get scale for text
 */
export function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number
) {
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed
  );
}

/**
 * Get bank slug string from bank name string
 */
export function getBankSlug(name: string) {
  return name.split(" ").join("-").toLowerCase();
}

interface Obj {
  [key: string]: any;
}

/**
 * Create object from an array of objects
 */
export function mapKeys<T extends Obj>(
  items: T[],
  key: string,
  exclude: string[] = []
) {
  return items.reduce((obj: T, currentObj: T) => {
    const newObject: any = {
      ...currentObj,
      ...exclude.map((k) => ({ [k]: undefined })),
    };

    (obj as any)[currentObj[key]] = newObject;
    return obj;
  }, {} as T);
}

/**
 * Create map from an array of objects
 */
export function createMap(items: Obj[], key: string, mappedKey?: string) {
  const map = new Map();
  items.forEach((item) => {
    map.set(item[key], mappedKey ? item[mappedKey] : item);
  });
  return map;
}

/**
 * Format  string to a `{MMM} {dd}, {yyyy}` format
 * @param dateStr
 * @param format (optional defaults to MMM DD, YYYY)
 */
export function formatDateStr(dateStr: string | number, format?: string) {
  return dayjs(dayjs(dateStr)).format(format || "MMM DD, YYYY");
}

/**
 * Pasre a  string
 * @param dateStr
 * @param format
 * @returns dayjs.Dayjs
 */
export function parseDate(
  dateStr: string,
  format?: dayjs.OptionType | undefined
) {
  return dayjs(dateStr, format);
}

export function parseDOB(dateStr: string, format?: string) {
  return parseDate(dateStr, "DD/MM/YYYY").format(format || "MMM DD, YYYY");
}

export function isValidDate(dateStr: string) {
  return dayjs(dateStr).isValid();
}

export function getDateDifference(firstDate: string, secondDate: string) {
  const firstDayInDayJS = dayjs(firstDate);
  const secondDayInDayJs = dayjs(secondDate);

  const difference = firstDayInDayJS.diff(secondDayInDayJs, undefined, false);

  const duration = dayjs.duration(difference);

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return { days, hours, minutes, seconds };
}

/**
 * Get time string form a date string
 */
export function getDateTime(
  dateStr: string,
  timeFormat: "12h" | "24h" = "24h"
) {
  const format = timeFormat === "12h" ? "hh:mma" : "HH:mm";
  return dayjs(new Date(dateStr)).format(format);
}

/**
 * get relative time from now
 */
export function getRelativeTime(time: Date) {
  return dayjs(time).fromNow();
}

export function isDateToday(dateStr: string) {
  return dayjs(new Date(dateStr)).isToday();
}

export function isDateYesterday(dateStr: string) {
  return dayjs(new Date(dateStr)).isYesterday();
}

export function addDaysToDate(dateStr: string, days: number) {
  return dayjs(new Date(dateStr)).add(days, "day");
}

export function getDateFromMillisecondsSinceEpoch(
  milliseconds: number,
  format?: string
) {
  return dayjs(dayjs(milliseconds)).format(format || "mm:ss");
}

/**
 * Format the transaction amount which comes in the format of '{CURRENCY} {AMOUNT}`
 */
export function formatTransactionAmountStr(
  str: string,
  type: "debit" | "credit" = "credit"
) {
  const [currency, amount] = str.split(" ");
  const formattedAmount = formatMoneyNoCurrency(Number(amount), undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
  const sign = type === "credit" ? "" : "-";

  return `${sign}${formattedAmount} ${currency}`;
}

export function isString(val: unknown) {
  return typeof val === "string";
}

export const getFiatEquivalent = (
  a: Account,
  type: keyof FiatAccount = "balance"
) => {
  let val = Number.parseFloat(a.fiat![type] || "0") || 0;
  return val;
};

export const getCryptoEquivalent = (
  a: Account,
  type: keyof FiatAccount = "balance"
) => {
  let val = 0;
  if (a.fiat && a.fiat[type]) {
    val = Number.parseFloat(a.fiat[type]);
  }

  return val;
};

export const getSpotBalanceWithType = (
  accounts: Account[],
  currencyCode = "USD",
  type: keyof FiatAccount
) => {
  let balance = 0;

  accounts.forEach((account) => {
    if (
      account.type !== "fiat" &&
      account.fiat != null &&
      account.fiat![type as keyof FiatAccount] !== ""
    ) {
      const fiatBalance = getFiatEquivalent(account, type);
      balance += fiatBalance;
    } else if (
      account.type === "fiat" &&
      account.currency.toLowerCase() === currencyCode.toLowerCase()
    ) {
      const accountBalance = Number.parseFloat(
        account[type as keyof FiatAccount] || "0"
      );
      balance += accountBalance;
    } else {
      const cryptoBalance = getCryptoEquivalent(account, type);
      balance += cryptoBalance;
    }
  });

  return formatToFractionalMoney(balance.toString(), currencyCode);
};

export const getSpotAccountBalance = (
  accounts: Account[],
  currencyCode = "USD"
) => {
  let balance = 0;

  accounts.forEach((account) => {
    if (
      account.type !== "fiat" &&
      account.fiat != null &&
      account.fiat!.balance !== ""
    ) {
      const fiatBalance = getFiatEquivalent(account);
      balance += fiatBalance;
    } else if (
      account.type === "fiat" &&
      account.currency.toLowerCase() === currencyCode.toLowerCase()
    ) {
      const accountBalance = Number.parseFloat(account.balance || "0");
      balance += accountBalance;
    } else {
      const cryptoBalance = getCryptoEquivalent(account);
      balance += cryptoBalance;
    }
  });

  return balance;
};

export function getAccountBalanceInFractionalFormat(
  accounts: Account[],
  currencyCode = "USD"
) {
  const balance = getSpotAccountBalance(accounts, currencyCode);
  return formatToFractionalMoney(balance.toString(), currencyCode);
}

export function truncateAddress(address: string) {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 6
  )}`;
}

export const getUserBankFromPaymentAccount = (
  c: ConnectPaymentAccount
): UserBank => {
  let accountName = "";
  let accountNumber = "";
  let bankName = "";
  let bankCode = "";

  for (let index = 0; index < c.fields.length; index++) {
    const field = c.fields[index];

    switch (field.display_name.toLowerCase()) {
      case "account name":
        accountName = field.value;
        break;

      case "account number":
        accountNumber = field.value;
        break;

      case "phone number":
        accountNumber = field.value;
        break;

      case "bank name":
        bankName = field.value;
        break;

      case "bank code":
        bankCode = field.value;
        break;

      default:
        break;
    }
  }

  if (bankName.trim() === "") {
    bankName = c.payment_method.name;
  }

  if (accountName.trim() === "") {
    accountName = "No name";
  }

  return {
    id: c.id,
    name: bankName,
    accountNumber,
    accountName,
    code: bankCode,
  };
};

export const getUserBankFormatFromPaymentAccount = (
  c: ConnectPaymentAccount
): UserBank => {
  let accountName = "";
  let accountNumber = "";

  for (let index = 0; index < c.fields.length; index++) {
    const field = c.fields[index];

    if (
      field.display_name &&
      field.display_name.replaceAll(" ", "").toLowerCase().includes("account")
    ) {
      accountName = field.value;
    }

    if (
      field.display_name &&
      field.display_name.toLowerCase().includes("phone")
    ) {
      accountNumber = field.value;
    }
  }

  if (!accountNumber) {
    accountNumber = c.fields[0].value;
  }

  return {
    id: c.id,
    accountName,
    accountNumber,
    code: "",
    name: c.payment_method.name,
  };
};

export const numberToTimeUnit = (t: number) => {
  if (t < 60) return `${t} sec${t > 1 ? "s" : ""}`;
  else if (t / 60 < 60) {
    const minuteEquivalent = Math.ceil(t / 60);
    return `${minuteEquivalent} min${minuteEquivalent > 1 ? "s" : ""}`;
  }

  const hourEquivalent = Math.ceil(t / 3600);
  return `${hourEquivalent} hour${hourEquivalent > 1 ? "s" : ""}`;
};

export const hasTimeElapsed = (time: string | Date) => {
  const now = Date.now();
  const expiry = new Date(time).valueOf();

  const diff = expiry - now;
  if (diff > 0) return false;
  return true;
};

export const truncateString = (text: string, length: number = 10) => {
  return `${text.substring(0, length)}...${text.substring(
    text.length - length + 3
  )}`;
};

export const toPreciseNumber = (value: number, precision = 2) => {
  const valueInString = value.toString();
  if (!valueInString.includes(".")) {
    return value;
  }

  const splittedValue = valueInString.split(".");

  if (splittedValue[1].length === 0) {
    return value;
  }

  return Number.parseFloat(
    valueInString.substring(0, valueInString.indexOf(".") + precision + 1)
  );
};

interface Event {
  timestamp: string;
}

export function sortDataBasedOnGroup<T extends Event>(e: T[]) {
  const events = [...e];

  //sort events based on dates in descending order
  events.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  /**
   * this holds ordered events in groups.
   * Each group of events holds events that occured on the same day, in descending order too.
   */
  const processedEvents: T[][] = [];

  const eventLength = events.length;

  for (let index = 0; index < eventLength; index++) {
    const currentEvent = events[index];
    if (index === 0) {
      processedEvents.push([currentEvent]);
    } else {
      const prevEvent = events[index - 1];
      if (
        new Date(prevEvent.timestamp).getDate() ===
        new Date(currentEvent.timestamp).getDate()
      ) {
        processedEvents[processedEvents.length - 1].push(currentEvent);
      } else {
        processedEvents.push([currentEvent]);
      }
    }
  }

  return processedEvents;
}

export function getInitials(name: string) {
  const [firstName, lastName] = name.split(" ");

  if (lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return `${firstName[0]}${firstName[1]}`;
}
