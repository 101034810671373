import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getNotificationsCount } from "app/api/notifications";

const useGetNotificationsCount = () => {
  const { isLoading, error, isFetching, refetch, data } = useQuery(
    ["notifications", "count"],
    () => getNotificationsCount()
  );

  return {
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    isFetching,
    getCounts: refetch,
    counts: data?.data.data,
  };
};

export default useGetNotificationsCount;
