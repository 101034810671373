import * as React from "react";
import { Combobox, Transition } from "@headlessui/react";

import tw, { styled } from "twin.macro";
import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg";
import { ReactComponent as CancelIcon } from "app/assets/icons/close.svg";
import RecentSearch from "./RecentSearch";

import TopAssets from "./TopAssets";
import useGetAccounts from "app/hooks/useGetAccounts";
import Assets from "./Assets";

export default function GlobalSearchInput() {
  const [query, setQuery] = React.useState("");
  const [isFocus, setIsFocus] = React.useState(false);

  const { accounts } = useGetAccounts();

  const wallets = React.useMemo(() => {
    if (!query) return [];
    return accounts?.filter(
      (account) =>
        account.name.toLowerCase().includes(query.toLowerCase().trim()) ||
        account.currency.toLowerCase().includes(query.toLowerCase().trim())
    );
  }, [query, accounts]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleChange = () => {
    setQuery("");
    setIsFocus((v) => !v);
  };

  return (
    <Combobox>
      <InputContainer>
        <Combobox.Button as={SearchButton}>
          <SearchIcon />
        </Combobox.Button>

        {/* @ts-ignore */}
        <Combobox.Button tw="w-full">
          <Combobox.Input
            placeholder="Search for an asset"
            autoComplete="off"
            value={query}
            onChange={handleQueryChange}
            css={tw`w-[393px] tablet:w-full bg-transparent outline-none text-[16px] placeholder:text-black32 dark:placeholder:text-white32 dark:text-white95`}
          />
        </Combobox.Button>

        <CancelButton>
          {isFocus && (
            <button tw="flex items-center">
              <CancelIcon width={16} tw="text-[#000] dark:text-[#fff]" />
            </button>
          )}
        </CancelButton>
      </InputContainer>

      {/* @ts-ignore */}

      <Transition
        as="div"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterEnter={handleChange}
        afterLeave={handleChange}
      >
        <Backdrop aria-hidden="true" />

        <Combobox.Options as={Dropdown}>
          {query.length === 0 && (
            <>
              <RecentSearch />
              <TopAssets />
            </>
          )}

          {query.length > 0 && <Assets accounts={wallets!} />}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
}

const InputContainer = styled.div`
  ${tw`w-full flex items-center gap-[9px] px-[16px] py-[10px] rounded-[8px] border border-[#0000001F] focus-within:border-black transition-colors`};

  ${tw`dark:border-white30 dark:focus-within:border-white`};
`;

const Dropdown = styled.div`
  ${tw`[z-index: 999] [position: absolute] [top: 54px]`};

  ${tw`w-[480px] max-h-[556px] mobile:(min-h-[calc(100vh - 118px)] inset-x-0 w-full top-[109px] rounded-[0px] border-t dark:border-t-white12) tablet:(top-[104px] w-[380px]) p-[20px] dark:bg-[#000] dark:border-[rgba(255, 255, 255, 0.12)] overflow-auto`};
  ${tw`rounded-[16px] mobile:(border-none bg-[#f6f9fa] shadow-none) border border-transparent bg-white z-10 shadow-[0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.08)] mt-[10px]`};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchButton = styled.div`
  svg {
    path {
      ${tw`fill-transparent`};
    }
  }
`;

const CancelButton = styled.div`
  width: 15px;
  svg {
    path {
      ${tw`fill-[#000] dark:fill-[#fff]`};
    }
  }
`;

const Backdrop = tw.div`fixed mobile:(hidden) inset-0 top-[64px] tablet:(top-[120px]) bg-black/30 dark:bg-[rgba(50, 53, 61, 0.3)]`;
