import SearchCurrency, { Currency } from "app/models/currency";

import request from "../request";
import { BaseResponse, GenericPaginatedResponse } from "../types";
import {
  GetCurrenciesParam,
  GetCurrencyOHLCPayload,
  GetCurrencyOHLCResponse,
  SearchCurrenciesParam,
} from "./types";

export const searchCurrencies = (params: SearchCurrenciesParam) => {
  return request.get<GenericPaginatedResponse<SearchCurrency>>(
    "/currency/search",
    {
      params,
    }
  );
};

export const addToWatchList = (asset: string) => {
  return request.put<BaseResponse>(`/currency/${asset}/follow`);
};

export const getCurrency = (id: string) => {
  return request.get<Currency>(`/currency/${id}`);
};

export const getCurrencies = (params: GetCurrenciesParam) => {
  return request.get<GenericPaginatedResponse<Currency>>("/currency", {
    params,
  });
};

export const getCurrencyOHLC = ({
  symbol,
  ...params
}: GetCurrencyOHLCPayload) => {
  return request.get<GetCurrencyOHLCResponse>(`/currency/ohlc/${symbol}`, {
    params,
  });
};
