import tw, { styled } from "twin.macro";

import Card from "app/components/ui/Card";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import { ReactComponent as CaretRight } from "app/assets/icons/caret-right.svg";
import useGetSupportedCountries from "app/hooks/useGetSupportedCountries";
import LoaderContainer from "app/components/ui/LoaderContainer";

interface Props {
  onContinue: (c: string) => void;
}

export default function SelectCurrency(props: Props) {
  const { onContinue } = props;

  const { countries, isLoading, error, getCountries } =
    useGetSupportedCountries();

  return (
    <LoaderContainer
      loading={isLoading}
      error={!!error}
      errorMessage={error}
      onRetry={getCountries}
    >
      <div>
        <Header tw="mb-[24px] text-[28px]">Select currency</Header>

        <Card tw="flex flex-col gap-[16px] px-[20px] pt-[20px] pb-[16px]">
          {countries?.map((country, idx) => (
            <CurrencyTile
              key={country.id}
              role="button"
              onClick={() => onContinue(country.currency)}
            >
              <div tw="flex items-center gap-[10px]">
                <img
                  src={country.flag}
                  alt={country.name}
                  tw="w-[40px] h-[40px] rounded-[9999px] object-cover"
                />

                <div>
                  <Text medium tw="uppercase">
                    {country.currency}
                  </Text>

                  <Text tw="text-black32 dark:text-white32">
                    {country.name}
                  </Text>
                </div>
              </div>

              <CaretRight tw="text-black32 dark:text-white32" />
            </CurrencyTile>
          ))}
        </Card>
      </div>
    </LoaderContainer>
  );
}

const CurrencyTile = styled.div`
  ${tw`flex justify-between items-center not-last-of-type:border-b border-[#00000014] dark:border-[#FFFFFF14] not-last-of-type:pb-[16px]`}
`;
