import * as React from "react";
import * as Sentry from "@sentry/react";
import { ReactNode, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

import useAuth from "app/hooks/useAuth";
import useGetProfile from "app/hooks/useGetProfile";
import media from "app/styles/media";
import DashboardHooksCall from "app/components/DashboardHooksCall";
import useRefreshUserSession from "app/hooks/useRefreshUserSession";
import Announcements from "app/views/Announcements";
import { useProfile } from "app/providers/ProfileProvider";
import { gotoLogin } from "app/lib/logout";
import {
  DASHBOARD_BODY_PADDING_MOBILE_X,
  DASHBOARD_BODY_PADDING_MOBILE_Y,
  DASHBOARD_BODY_PADDING_X,
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  DASHBOARD_MOBILE_NAV_HEIGHT,
  DASHBOARD_SIDEBAR_WIDTH,
} from "app/constants/variables";

import LoaderContainer from "./ui/LoaderContainer";
import NetworkLoader from "./ui/NetworkLoader";
import ErrorWidget from "./ui/ErrorWidget";
import Loader from "./ui/Loader";
import AccountHalt from "app/components/AccountHalt";

const DashboardErrorWidget = (props: any) => (
  <ErrorWidget {...props} dashboard />
);

interface Props {
  header: ReactNode;
  sidenav: ReactNode;
  mobilenav: ReactNode;
}

export default function DashboardLayout(props: Props) {
  const { header, sidenav, mobilenav } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const isLoggedIn = useAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      gotoLogin();
    }
  }, [isLoggedIn]);

  const location = useLocation();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scroll(0, 0);
    }
  }, [location.pathname]);

  const { isLoading: refreshingSession } = useRefreshUserSession();

  const { isInitialLoading, isRefetching, error, refetch } =
    useGetProfile(isLoggedIn);

  const profile = useProfile();

  const loading = isInitialLoading || (refreshingSession && !profile);

  const isUserPendingDeletion = profile?.status === "pending";

  return (
    <>
      {isRefetching ? <NetworkLoader page={false} /> : null}
      <LoaderContainer
        screen
        loading={loading}
        error={!!error}
        errorMessage={error}
        onRetry={refetch}
      >
        {profile && (
          <Container>
            <div className="header">{header}</div>

            <section className="body">
              {!isUserPendingDeletion && (
                <aside className="sidebar">{sidenav}</aside>
              )}

              <Sentry.ErrorBoundary
                fallback={DashboardErrorWidget}
                beforeCapture={(scope) => {
                  scope.setTag("location", "dashboard");
                }}
              >
                {refreshingSession ? (
                  <div tw="w-full h-page flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <main className="content" ref={contentRef}>
                    {isUserPendingDeletion ? (
                      <AccountHalt />
                    ) : (
                      <>
                        {/* need to move this guys outa here and pass em as props */}
                        <Announcements />
                        <DashboardHooksCall>
                          <React.Suspense
                            fallback={<LoaderContainer screen loading />}
                          >
                            <Outlet />
                          </React.Suspense>
                        </DashboardHooksCall>
                      </>
                    )}
                  </main>
                )}
              </Sentry.ErrorBoundary>
            </section>

            {!isUserPendingDeletion && <footer>{mobilenav}</footer>}
          </Container>
        )}
      </LoaderContainer>
    </>
  );
}

const Container = styled.section`
  ${tw`w-full min-h-dvh h-full flex flex-col`};

  ${tw`bg-greyScale05`};

  ${tw`dark:bg-black`};

  > .header {
    min-height: ${DASHBOARD_HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
    display: flex;
    ${tw`z-40 border-b`};
    ${tw`bg-white `};

    ${tw`dark:bg-black dark:border-b-white12`};
  }

  > .body {
    ${tw`flex-grow w-full flex`};
    height: calc(100% - ${DASHBOARD_HEADER_HEIGHT}px);

    ${media.tablet} {
      height: calc(
        100% - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_MOBILE_NAV_HEIGHT}px
      );
    }
  }

  > .body > .sidebar {
    width: ${DASHBOARD_SIDEBAR_WIDTH}px;
    height: 100%;
    position: sticky;
    top: ${DASHBOARD_HEADER_HEIGHT}px;
    flex-shrink: 0;

    ${tw`tablet:hidden`};
  }

  > .body > .content {
    ${tw`w-full h-full overflow-x-hidden overflow-y-auto max-w-[1178px] mx-auto`};

    padding-left: ${DASHBOARD_BODY_PADDING_X}px;
    padding-right: ${DASHBOARD_BODY_PADDING_X}px;

    padding-top: ${DASHBOARD_BODY_PADDING_Y}px;
    /* padding-bottom: ${DASHBOARD_BODY_PADDING_Y}px; */

    ${media.tablet} {
      padding-left: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;
      padding-right: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;

      padding-top: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
      padding-bottom: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
    }

    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  > footer {
    flex-shrink: 0;
    height: ${DASHBOARD_MOBILE_NAV_HEIGHT}px;
    position: sticky;
    bottom: 0;

    ${tw`w-full hidden tablet:block`};
  }
`;
